import Api from '../Api'
import Axios from 'axios';
// var config = {
//     headers: {'Access-Control-Allow-Origin': '*'}
// };
export default {
  // Get list of all active (status true) cinemas for dropdown
  getCinemasListData(payload) {
    // return Api().get('api/dashuser/GetddlCinemaList?OrgID=' + orgID)
    return Api().post('api/dashuser/GetCinemaListddl/', payload)
  },
  getCinemasLocationData(payload) {
    return Api().post('api/dashuser/GetCinemaLocation/', payload)
    // return Api().get('api/dashuser/GetCinemaLocation/')
  },
  getCinemasMoviesListData(payload) {
    return Api().post('api/dashuser/GetMoviesByCinemaID/', payload)
  },
  getCategoryListData(payload) {
    return Api().post('api/dashuser/GetddlCategoryList', payload)
  },
  getReportFinalListData(payload) {
    return Api().post('api/dashuser/GetScreeningReport/', payload)
  },
  getSeatLayoutData(payload) {
    return Api().post('api/GetSeatLayout/', payload)
  },
  getShowReportsData(payload) {
    return Api().post('api/dashuser/GetCinemaShowReport/', payload)
  },
  getTransactionReportsData(payload) {
    return Api().post('api/dashuser/GetCinemaTransReport/', payload)
  },
  getDistributorListData() {
    return Api().post('api/dashuser/GetddlDistributorList/')
  }

}
