<script>
// import { Bar } from "vue-chartjs";

// export default {
//   extends: Bar,
//   data: () => ({
//     chartdata: {
//       datacollection: {
//         labels: ["January", "February"],
//         datasets: [
//           {
//             label: "Data One",
//             backgroundColor: "#f87979",
//             data: [40, 20]
//           }
//         ]
//       }
//     },
//     options: {
//       responsive: true,
//       maintainAspectRatio: false
//     }
//   }),

//   mounted() {
//     this.renderChart(this.datacollection, this.options);
//   }
// };
import { Bar } from "vue-chartjs";
import { CustomTooltips } from "@coreui/coreui-plugin-chartjs-custom-tooltips";

export default {
  extends: Bar,
  mounted() {
    // Overwriting base render method with actual data.
    this.renderChart(
      {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December"
        ],
        datasets: [
          {
            label: "GitHub Commits",
            backgroundColor: "#f87979",
            data: [20, 20, 20, 20, 10, 20, 20, 21, 18, 22, 15, 11]
          }
        ]
      },
      {
        responsive: true,
        maintainAspectRatio: true,
        tooltips: {
          enabled: false,
          custom: CustomTooltips,
          intersect: true,
          mode: "index",
          position: "nearest",
          callbacks: {
            labelColor: function(tooltipItem, chart) {
              return {
                backgroundColor:
                  chart.data.datasets[tooltipItem.datasetIndex].backgroundColor
              };
            }
          }
        }
      }
    );
  }
};
</script>
